import { Title } from '@solidjs/meta';
import { Container, Heading, HorizontalRule, Page, Section, TextLink } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { PictureSection } from '../../components/layouts/picture-section';
import type { RouteDefinition } from '@solidjs/router';

export default function EventPage() {
	return (
		<NoHydration>
			<Title>October 2024 Southwest Troon Coach Summit | Events | Troon</Title>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/web/events/troon-coach-summit/hero.jpeg`}>
				<Heading as="h1">Southwest Troon Coach Summit</Heading>
				<p>October 14-15 at Troon North</p>
			</Hero>

			<Container>
				<Page>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/web/events/troon-coach-summit/details.jpeg`}>
						<Heading as="h2">Southwest Troon Coach Summit Event Details</Heading>
						<ul class="list-disc ps-6">
							<li>
								<b>Dates:</b> October 14 – 15, 2024
							</li>
							<li>
								<b>Location:</b> Troon North Golf Club at 10320 E Dynamite Blvd, Scottsdale, AZ 85262
							</li>
							<li>
								<b>Hotel:</b> A special hotel rate of $182 is available at the AC Kierland in Scottsdale; contact Steve
								Velastegui at svelastegui@troon.com to secure this rate
							</li>
						</ul>
					</PictureSection>

					<Section>
						<Heading as="h2" size="h3">
							Registration cost
						</Heading>

						<table>
							<thead>
								<tr>
									<th
										class="rounded-t border border-neutral bg-brand-700 px-4 py-2 text-start font-semibold text-white"
										colSpan={3}
									>
										Registration cost - Troon Employee
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" class="border border-neutral px-4 py-2 text-start font-normal">
										Both Days (Monday & Tuesday) Includes: 2-days of Seminars, and lunch on Tuesday
									</th>
									<td class="border border-neutral px-4 py-2">$295</td>
									<td class="border border-neutral px-4 py-2">
										<TextLink href="https://clients.uschedule.com/princewilliamgolf/booking/event/eventdates/43203">
											Register here<span class="sr-only"> for both days</span>
										</TextLink>
									</td>
								</tr>
								<tr>
									<th scope="row" class="border border-neutral px-4 py-2 text-start font-normal">
										Monday Only – Includes: 1/2 day session
									</th>
									<td class="border border-neutral px-4 py-2">$100</td>
									<td class="border border-neutral px-4 py-2">
										<TextLink href="https://clients.uschedule.com/princewilliamgolf/booking/event/eventdates/42585">
											Register here<span class="sr-only"> for both days</span>
										</TextLink>
									</td>
								</tr>
								<tr>
									<th scope="row" class="border border-neutral px-4 py-2 text-start font-normal">
										Tuesday Only – Includes 1-full day session along with breakfast & lunch
									</th>
									<td class="border border-neutral px-4 py-2">$195</td>
									<td class="border border-neutral px-4 py-2">
										<TextLink href="https://clients.uschedule.com/princewilliamgolf/booking/event/eventdates/43344">
											Register here<span class="sr-only"> for both days</span>
										</TextLink>
									</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th
										class="rounded-t border border-neutral bg-brand-700 px-4 py-2 text-start font-semibold text-white"
										colSpan={3}
									>
										Registration cost - Non-Troon Employee
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" class="border border-neutral px-4 py-2 text-start font-normal">
										Both Days (Monday & Tuesday) Includes: 2-days of Seminars, and lunch on Tuesday
									</th>
									<td class="border border-neutral px-4 py-2">$395</td>
									<td class="border border-neutral px-4 py-2">
										<TextLink href="https://clients.uschedule.com/princewilliamgolf/booking/event/eventdates/42598">
											Register here<span class="sr-only"> for both days</span>
										</TextLink>
									</td>
								</tr>
								<tr>
									<th scope="row" class="border border-neutral px-4 py-2 text-start font-normal">
										Monday Only – Includes: 1/2 day session
									</th>
									<td class="border border-neutral px-4 py-2">$130</td>
									<td class="border border-neutral px-4 py-2">
										<TextLink href="https://clients.uschedule.com/princewilliamgolf/booking/event/eventdates/42597">
											Register here<span class="sr-only"> for both days</span>
										</TextLink>
									</td>
								</tr>
								<tr>
									<th scope="row" class="border border-neutral px-4 py-2 text-start font-normal">
										Tuesday Only – Includes 1-full day session along with breakfast & lunch
									</th>
									<td class="border border-neutral px-4 py-2">$265</td>
									<td class="border border-neutral px-4 py-2">
										<TextLink href="https://clients.uschedule.com/princewilliamgolf/booking/event/eventdates/42586">
											Register here<span class="sr-only"> for both days</span>
										</TextLink>
									</td>
								</tr>
							</tbody>
						</table>
					</Section>
					<HorizontalRule />

					<PictureSection reverse src={`${getConfigValue('IMAGE_HOST')}/web/events/troon-coach-summit/more.jpeg`}>
						<Heading as="h2">More Information</Heading>
						<p>
							We’re excited to host the Fall Southwest Troon Coach Summit at Troon North in Scottsdale, AZ, on October
							14 -15. This event is a fantastic opportunity to continue learning, growing, and improving as a coach
							while earning MSR points.
						</p>

						<p>
							Over the course of two days, you’ll network with fellow coaches, attend insightful sessions led by the
							following speakers:
						</p>

						<ul class="list-disc ps-6">
							<li>Chuck Cook – Golf Digest – Hall Fame Coach</li>
							<li>Tim Mahoney – Global Director of Coaching & Education, Troon</li>
							<li>Mike Malaska – Top 50 Instructor</li>
							<li>Dr. Sasho Mackenzie – Biomechanics – The Stack System</li>
							<li>SportsBox AI – Dr. Phil Cheetham</li>
							<li>Vision 54</li>
							<li>Top Tracer – Don Rea, Vice President of the PGA, Andrew Workman – Augusta Ranch</li>
							<li>True Spec – Off site – Hands on Fitting Seminar</li>
						</ul>

						<p>
							The summit includes a lunch and continental breakfast on Tuesday. Register for both days for $295, or
							choose to attend Monday only for $100 or Tuesday only for $195. Don’t miss this chance to expand your
							knowledge and open new doors in your coaching career!
						</p>
					</PictureSection>
				</Page>
			</Container>
		</NoHydration>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
